import React, { useState } from 'react';
import PropTypes from 'prop-types';
import ReactApexChart from 'react-apexcharts';
import { Card, CardActions, CardContent, CardHeader, Checkbox, FormControlLabel, FormGroup } from '@material-ui/core';



const CartLeadsInDepartmentByTime = props => {
    const { series = [], categories = [], title, subheader, stacked = false, stackChangeable = false } = props;

    const handleStackedPreference = () => {
        setOptionalStacked(!optionalStacked)
    }
    const [optionalStacked, setOptionalStacked] = useState(stacked);
    const chartKey = Math.floor(Math.random() * 1001);

    const cartData = {
        options: {
            chart: {
                type: 'bar',
                id: "CartLeadsInDepartmentByTime",
                stacked: optionalStacked
            },
            xaxis: {
                categories: categories
            },
            dataLabels: {
                formatter: function (val, opts) {
                    console.info(val, opts, series[opts.seriesIndex].data[opts.dataPointIndex]);
                    return series[opts.seriesIndex].data[opts.dataPointIndex];
                },
            }
        },
        series: series
    };

    if (optionalStacked) {
        cartData.options.chart.stackType = '100%';
    }

    return (categories && series) ?
        <Card>
            <CardHeader
                title={title}
                subheader={subheader}>
            </CardHeader>
            <CardContent>
                <ReactApexChart
                    type="bar"
                    options={cartData.options}
                    series={cartData.series}
                    height={350}
                    key={chartKey}
                />
            </CardContent>
            {
                stackChangeable && (
                    <CardActions>
                        <FormGroup>
                            <FormControlLabel control={
                                <Checkbox
                                    checked={optionalStacked}
                                    onClick={handleStackedPreference}
                                />
                            } label="Stacked" />
                        </FormGroup>
                    </CardActions>
                )
            }

        </Card> : <>loading</>

};

CartLeadsInDepartmentByTime.propTypes = {
    series: PropTypes.array,
    categories: PropTypes.array,
};

export default CartLeadsInDepartmentByTime;
